import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-0b181782")
const _hoisted_1 = { class: "rows" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TranslationEntryLocaleRow = _resolveComponent("TranslationEntryLocaleRow")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.visibleTexts, ({ locale, text, base, published, basePublished }) => {
      return (_openBlock(), _createBlock(_component_TranslationEntryLocaleRow, {
        key: locale,
        locale: locale,
        text: text,
        "base-text": base,
        selected: _ctx.selected,
        "show-published": _ctx.showPublished,
        published: published,
        "base-published": basePublished || '[none]',
        "onUpdate:text": (value) => _ctx.updated(locale,value)
      }, null, 8, ["locale", "text", "base-text", "selected", "show-published", "published", "base-published", "onUpdate:text"]))
    }), 128))
  ]))
}