import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-1b4746ba")
const _hoisted_1 = { class: "p-grid p-d-flex p-ai-center p-pb-1" }
const _hoisted_2 = { class: "p-col-1 p-p-1 p-m-0" }
const _hoisted_3 = ["textContent"]
const _hoisted_4 = ["textContent"]
const _hoisted_5 = ["textContent"]
const _hoisted_6 = ["innerHTML"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_country_flag = _resolveComponent("country-flag")!
  const _component_Textarea = _resolveComponent("Textarea")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_country_flag, {
        country: _ctx.country,
        size: "normal",
        title: _ctx.locale
      }, null, 8, ["country", "title"])
    ]),
    (_ctx.selected)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass({ 'p-col-11': !_ctx.showPublished, 'p-lg-5': _ctx.showPublished, 'p-p-1 p-m-0': true})
        }, [
          _createVNode(_component_Textarea, {
            modelValue: _ctx.editingText,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.editingText = $event)),
            style: {"width":"100%"},
            autoResize: true,
            class: "p-p-1 p-m-0 p-input text-sm p-d-flex",
            placeholder: _ctx.baseText
          }, null, 8, ["modelValue", "placeholder"])
        ], 2))
      : (_ctx.text)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass({ 'p-col-11': !_ctx.showPublished, 'p-lg-5': _ctx.showPublished, text: true, 'p-p-0 p-m-0':true}),
            textContent: _toDisplayString(_ctx.text)
          }, null, 10, _hoisted_3))
        : (_openBlock(), _createElementBlock("div", {
            key: 2,
            class: _normalizeClass({ 'p-col-11': !_ctx.showPublished, 'p-lg-5': _ctx.showPublished, text: true, 'p-p-0 p-m-0':true, 'p-text-italic': true, inherited: true}),
            textContent: _toDisplayString(_ctx.baseText)
          }, null, 10, _hoisted_4)),
    (_ctx.showPublished && _ctx.published)
      ? (_openBlock(), _createElementBlock("div", {
          key: 3,
          class: "p-col-11 p-lg-5 p-text-bold text p-p-0 p-m-0",
          textContent: _toDisplayString(_ctx.published)
        }, null, 8, _hoisted_5))
      : (_ctx.showPublished)
        ? (_openBlock(), _createElementBlock("div", {
            key: 4,
            class: "p-col-11 p-lg-5 p-text-bold text p-text-italic inherited p-p-0 p-m-0",
            innerHTML: _ctx.basePublished
          }, null, 8, _hoisted_6))
        : _createCommentVNode("", true)
  ]))
}