
import Textarea from 'primevue/textarea'
import { computed, defineComponent } from 'vue'
import CountryFlag from 'vue-country-flag-next'

export default defineComponent({
  name: 'TranslationEntryLocaleRow',
  components: {
    CountryFlag,
    Textarea
  },
  emits: [
    'update:text'
  ],
  props: {
    locale: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: false
    },
    baseText: {
      type: String,
      required: false,
      default: ''
    },
    selected: {
      type: Boolean,
      required: false,
      default: false
    },
    showPublished: {
      type: Boolean,
      required: false,
      default: false
    },
    published: {
      type: String,
      required: false,
      default: ''
    },
    basePublished: {
      type: String,
      required: false,
      default: ''
    }
  },

  setup (props, { emit }) {
    const editingText = computed({
      get () {
        return props.text
      },
      set (value) {
        emit('update:text', value)
      }
    })
    return {
      country: props.locale.substring(3),
      editingText
    }
  }
})
