
import TranslationEntryLocaleRow from '@/components/translations/TranslationEntryLocaleRow.vue'
import { TranslationEntry, TranslationsMap } from '@/models/translations/TranslationEntry.model'
import { TranslationLanguage } from '@/models/translations/TranslationLanguage.model'
import { computed, defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'TranslationEntryTextsEditor',
  components: {
    TranslationEntryLocaleRow
  },
  emits: [
    'update:entry'
  ],
  props: {
    entry: {
      type: Object as PropType<TranslationEntry>,
      required: true
    },
    selected: {
      type: Boolean,
      required: false,
      default: false
    },
    languages: {
      type: Object as PropType<Array<TranslationLanguage>>,
      required: true
    },
    showPublished: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup (props, { emit }) {
    const visibleTexts = computed(() => {
      const languages = props.languages
      const draft = props.entry.draft
      const published = props.entry.published

      const findBaseText = (baseId: string | null | undefined, texts: TranslationsMap): string | null | undefined => {
        const baseLanguage = baseId && languages.find(l => l.id === baseId)
        return baseLanguage && (texts[baseLanguage.locale] || (baseLanguage.baseLanguageId && findBaseText(baseLanguage.baseLanguageId, texts)))
      }

      return languages.map(({ locale, baseLanguageId }) => ({
        locale,
        text: draft[locale],
        base: findBaseText(baseLanguageId, draft),
        published: published[locale],
        basePublished: findBaseText(baseLanguageId, published)
      }))
    })

    const updated = (locale: string, value: string | null) => {
      const draft = props.entry.draft

      const newDraft = { ...draft, [locale]: value }

      emit('update:entry', { ...props.entry, draft: newDraft })
    }

    return {
      updated,
      visibleTexts
    }
  }
})
