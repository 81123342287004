import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "p-d-flex p-justify-between p-mb-2" }
const _hoisted_2 = ["textContent"]
const _hoisted_3 = { class: "p-buttonset" }
const _hoisted_4 = { class: "p-d-flex p-jc-between" }
const _hoisted_5 = { class: "p-m-0" }
const _hoisted_6 = { class: "p-d-flex p-jc-between" }
const _hoisted_7 = { class: "p-inputgroup p-mr-2" }
const _hoisted_8 = { class: "p-inputgroup p-mr-2" }
const _hoisted_9 = { class: "p-buttonset p-d-flex p-jc-between" }
const _hoisted_10 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_TranslationsLanguagesSelectorPanel = _resolveComponent("TranslationsLanguagesSelectorPanel")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_ToggleButton = _resolveComponent("ToggleButton")!
  const _component_Column = _resolveComponent("Column")!
  const _component_TranslationEntryTextsEditor = _resolveComponent("TranslationEntryTextsEditor")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_DataTable, {
      selection: _ctx.currentEntry,
      "onUpdate:selection": _cache[6] || (_cache[6] = ($event: any) => (_ctx.currentEntry = $event)),
      loading: _ctx.loading,
      value: _ctx.entries,
      dataKey: "id",
      class: "p-datatable-sm",
      responsiveLayout: "stack",
      breakpoint: "960px",
      scrollHeight: "flex",
      selectionMode: "single",
      filters: _ctx.filters,
      "onUpdate:filters": _cache[7] || (_cache[7] = ($event: any) => (_ctx.filters = $event))
    }, {
      header: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("h3", {
            class: "p-m-0 p-pb-2",
            textContent: _toDisplayString(_ctx.title)
          }, null, 8, _hoisted_2),
          _createElementVNode("span", _hoisted_3, [
            _createVNode(_component_Button, {
              icon: "pi pi-trash",
              label: "Borrar cache",
              onClick: _ctx.clearCache
            }, null, 8, ["onClick"]),
            _createVNode(_component_Button, {
              icon: "pi pi-download",
              label: "Descargar",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onClickDownloadCSV($event)))
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h3", _hoisted_5, [
            _createVNode(_component_TranslationsLanguagesSelectorPanel, {
              context: _ctx.context,
              languages: _ctx.allLanguages,
              modelValue: _ctx.selectedLanguages,
              "onUpdate:modelValue": [
                _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectedLanguages = $event)),
                _ctx.onUpdateSelectedLanguages
              ]
            }, null, 8, ["context", "languages", "modelValue", "onUpdate:modelValue"])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_InputText, {
                modelValue: _ctx.keyToAdd,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.keyToAdd = $event)),
                placeholder: "Nueva key",
                size: "40"
              }, null, 8, ["modelValue"]),
              _createVNode(_component_Button, {
                icon: "pi pi-plus",
                onClick: _ctx.addKey,
                disabled: !(_ctx.keyToAdd.trim())
              }, null, 8, ["onClick", "disabled"])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_InputText, {
                modelValue: _ctx.filters['key'].value,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.filters['key'].value = $event)),
                placeholder: "Buscar por key"
              }, null, 8, ["modelValue"]),
              _createVNode(_component_Button, { icon: "pi pi-search" })
            ]),
            _createElementVNode("span", _hoisted_9, [
              _createVNode(_component_ToggleButton, {
                "on-icon": "pi pi-eye",
                "off-icon": "pi pi-eye-slash",
                modelValue: _ctx.showPublished,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_ctx.showPublished = $event)),
                "on-label": "Ocultar",
                "off-label": "Publicados"
              }, null, 8, ["modelValue"])
            ])
          ])
        ])
      ]),
      footer: _withCtx(() => [
        (!_ctx.currentEntry)
          ? (_openBlock(), _createElementBlock("span", _hoisted_10, "Selecciona una fila para editarla"))
          : _createCommentVNode("", true)
      ]),
      default: _withCtx(() => [
        _createVNode(_component_Column, {
          field: "key",
          header: "Key",
          headerStyle: "width: 20%"
        }),
        _createVNode(_component_Column, {
          header: "",
          style: {"width":"4em"}
        }, {
          body: _withCtx(({data}) => [
            (data.id === _ctx.currentEntry?.id && _ctx.superuser)
              ? (_openBlock(), _createBlock(_component_Button, {
                  key: 0,
                  icon: "pi pi-trash",
                  class: "p-button-rounded p-button-text",
                  onClick: _ctx.deleteCurrent
                }, null, 8, ["onClick"]))
              : _createCommentVNode("", true),
            (data.id === _ctx.currentEntry?.id && _ctx.superuser)
              ? (_openBlock(), _createBlock(_component_Button, {
                  key: 1,
                  icon: "pi pi-pencil",
                  class: "p-button-rounded p-button-text",
                  onClick: _ctx.editKey
                }, null, 8, ["onClick"]))
              : _createCommentVNode("", true),
            (data.id === _ctx.currentEntry?.id)
              ? (_openBlock(), _createBlock(_component_Button, {
                  key: 2,
                  icon: "pi pi-save",
                  class: "p-button-rounded p-button-text",
                  onClick: _ctx.saveCurrent
                }, null, 8, ["onClick"]))
              : _createCommentVNode("", true),
            (data.id === _ctx.currentEntry?.id)
              ? (_openBlock(), _createBlock(_component_Button, {
                  key: 3,
                  icon: "pi pi-undo",
                  class: "p-button-rounded p-button-text",
                  onClick: _ctx.cancelEditing
                }, null, 8, ["onClick"]))
              : _createCommentVNode("", true),
            (!_ctx.editingEntry && _ctx.shouldPublish(data))
              ? (_openBlock(), _createBlock(_component_Button, {
                  key: 4,
                  icon: "pi pi-forward",
                  class: "p-button-rounded p-button-text",
                  onClick: ($event: any) => (_ctx.publishCurrent(data))
                }, null, 8, ["onClick"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _createVNode(_component_Column, { header: "Traducciones" }, {
          body: _withCtx(({data}) => [
            (data.id === _ctx.editingEntry?.id)
              ? (_openBlock(), _createBlock(_component_TranslationEntryTextsEditor, {
                  key: 0,
                  entry: _ctx.editingEntry,
                  "onUpdate:entry": _cache[5] || (_cache[5] = ($event: any) => (_ctx.editingEntry = $event)),
                  selected: true,
                  languages: _ctx.languages,
                  "show-published": _ctx.showPublished
                }, null, 8, ["entry", "languages", "show-published"]))
              : (_openBlock(), _createBlock(_component_TranslationEntryTextsEditor, {
                  key: 1,
                  entry: data,
                  selected: false,
                  languages: _ctx.languages,
                  "show-published": _ctx.showPublished
                }, null, 8, ["entry", "languages", "show-published"]))
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["selection", "loading", "value", "filters"]),
    _createVNode(_component_DataTable, {
      value: _ctx.entries,
      dataKey: "id",
      ref: "exporDataTable",
      class: "p-d-none"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Column, {
          field: "key",
          header: "Key"
        }),
        _createVNode(_component_Column, {
          field: "published.es_ES",
          header: "es_ES"
        }),
        _createVNode(_component_Column, {
          field: "published.es_AR",
          header: "es_AR"
        }),
        _createVNode(_component_Column, {
          field: "published.es_MX",
          header: "es_MX"
        }),
        _createVNode(_component_Column, {
          field: "published.es_CO",
          header: "es_CO"
        }),
        _createVNode(_component_Column, {
          field: "published.es_CL",
          header: "es_CL"
        }),
        _createVNode(_component_Column, {
          field: "published.pt_BR",
          header: "pt_BR"
        }),
        _createVNode(_component_Column, {
          field: "published.es_US",
          header: "es_US"
        })
      ]),
      _: 1
    }, 8, ["value"])
  ], 64))
}