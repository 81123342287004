import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TranslationsKeysTable = _resolveComponent("TranslationsKeysTable")!

  return (_openBlock(), _createBlock(_component_TranslationsKeysTable, {
    context: _ctx.context,
    allLanguages: _ctx.languages,
    languages: _ctx.selectedLanguages,
    modelValue: _ctx.selectedLanguages,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectedLanguages = $event)),
    "onUpdate:selectedLanguages": _ctx.onSelectionLanguages,
    entries: _ctx.entries,
    selectedEntry: _ctx.selectedEntry,
    "onUpdate:selectedEntry": _ctx.onSelectionUpdated,
    loading: _ctx.loading,
    onOnAddKey: _ctx.addKey,
    onOnEditKey: _ctx.editKey,
    onOnDelete: _ctx.onDelete,
    onOnKeyEdited: _ctx.saveEntry,
    onOnCancelEditing: _ctx.onCancelEditing,
    onOnPublish: _ctx.onPublish,
    "onClear:cache": _ctx.onClearCache
  }, null, 8, ["context", "allLanguages", "languages", "modelValue", "onUpdate:selectedLanguages", "entries", "selectedEntry", "onUpdate:selectedEntry", "loading", "onOnAddKey", "onOnEditKey", "onOnDelete", "onOnKeyEdited", "onOnCancelEditing", "onOnPublish", "onClear:cache"]))
}