
import {
  addTranslationEntry,
  getTranslationsContexts,
  getTranslationsEntries,
  getTranslationsEntry,
  getTranslationsLanguages,
  publishTranslationEntry,
  updateTranslationDraft,
  updateTranslationKey,
  deleteTranslationEntry,
  clearTranslationsCache
} from '@/api/translations.api'
import TranslationsKeysTable from '@/components/translations/TranslationsKeysTable.vue'
// import TranslationsLanguagesSelectorPanel from '@/components/translations/TranslationsLanguagesSelectorPanel.vue'
import { ToastLife, ToastSeverities, ToastSummaries } from '@/models/components/toast/enums'
import { CountriesISO } from '@/models/enums'
import { IContext } from '@/models/translations/TranslationContext'
import { TranslationEntry } from '@/models/translations/TranslationEntry.model'
import { TranslationLanguage } from '@/models/translations/TranslationLanguage.model'
import { useToast } from 'primevue/usetoast'
import { defineComponent, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'TranslationsEdit',
  components: {
    // TranslationsLanguagesSelectorPanel,
    TranslationsKeysTable
  },
  setup () {
    const toast = useToast()
    const route = useRoute()
    const contextId = route.params.context.toString()
    const context = ref<IContext | undefined>(undefined)
    const languages = ref<Array<TranslationLanguage>>([])
    const entries = ref<Array<TranslationEntry>>([])
    const selectedEntry = ref<TranslationEntry | undefined>(undefined)
    const selectedLanguages = ref<Array<TranslationLanguage>>([])
    const loading = ref<boolean>(true)

    const addKey = async (key: string) => {
      console.log(`Trying to add ${key}`)
      if (key && context.value) {
        try {
          await addTranslationEntry(context.value.key, key)
          entries.value = await getTranslationsEntries(contextId)
          showSuccess('Se agregó la key ' + key)
        } catch (error: any) {
          toast.add({
            summary: 'No se pudo agregar la key ' + key,
            detail: error.message,
            severity: ToastSeverities.Error,
            life: ToastLife.Default
          })
        }
      } else {
        console.log('Cannot add key if context is missing')
      }
    }

    function showSuccess (summary: string): void {
      toast.add({ summary, severity: ToastSeverities.Success, life: ToastLife.Default })
    }

    const onCancelEditing = () => {
      selectedEntry.value = undefined
    }

    const editKey = async (entry: TranslationEntry) => {
      const newKey = prompt('Edit key', entry.key)

      try {
        if (!newKey?.trim()) throw new Error('Key incompleta')
        updateEntryInArray(await updateTranslationKey(entry, newKey))

        toast.add({ summary: 'Se actualizó la key', detail: `${entry.key} → ${newKey}`, severity: ToastSeverities.Success, life: ToastLife.Default })
      } catch (error) {
        toast.add({
          summary: 'No se pudo editar la key ' + entry.key,
          detail: (error instanceof Error && error.message) || '',
          severity: ToastSeverities.Error,
          life: ToastLife.Default
        })
      }
    }

    const onDelete = async (entry: TranslationEntry) => {
      const entryText = (() => {
        const text = entry.published || entry.draft
        return Object.keys(text).map((key) => `${key}: ${text[key]}`).join('\n')
      })()

      if (!(confirm(`Seguro que querés eliminar la traducción con key ${entry.key}?`) &&
        confirm(`¿Seguro? puede que algun texto de alguna aplicación se vea afectado, esto NO PUEDE DESHACERSE
        \n KEY: ${entry.key}
        \n TEXTO:\n ${entryText}`))
      ) return

      try {
        await deleteTranslationEntry(entry)
        toast.add({ summary: 'Se eliminó la key', detail: `${entry.key}`, severity: ToastSeverities.Success, life: ToastLife.Default })
      } catch (error) {
        toast.add({
          summary: 'No se pudo eliminar la key ' + entry.key,
          detail: (error instanceof Error && error.message) || '',
          severity: ToastSeverities.Error,
          life: ToastLife.Default
        })
      }
    }

    function updateEntryInArray (updatedValue: TranslationEntry): void {
      entries.value = entries.value.map(e => e.id === updatedValue.id ? updatedValue : e)
    }

    const onPublish = async (entry: TranslationEntry) => {
      updateEntryInArray(await publishTranslationEntry(entry))
      showSuccess('Se publicó la key ' + entry.key)
    }

    const saveEntry = async (entry: TranslationEntry) => {
      console.log(`Saving entry: ${JSON.stringify(entry, null, 2)}`)
      console.log(`Selected entry: ${JSON.stringify(entry, null, 2)}`)
      updateEntryInArray(await updateTranslationDraft(entry))

      showSuccess('Se actualizó la key ' + entry.key)

      selectedEntry.value = undefined
    }

    const onSelectionUpdated = async (newSelectedEntry: TranslationEntry | undefined) => {
      if (newSelectedEntry?.id === selectedEntry.value?.id) return

      const translationsEntryPromise = newSelectedEntry && getTranslationsEntry(newSelectedEntry.id)

      if (selectedEntry.value) {
        await saveEntry(selectedEntry.value)
      }

      if (newSelectedEntry) {
        console.log(`Loading Selected entry: ${newSelectedEntry.id}`)
        selectedEntry.value = await translationsEntryPromise
      } else {
        selectedEntry.value = newSelectedEntry
      }
    }

    const onClearCache = async () => {
      try {
        await clearTranslationsCache()
        showSuccess('Se limpó la cache de traducciones')
      } catch (error) {
        toast.add({
          summary: 'No se pudo limpiar la cache de traducciones',
          detail: (error instanceof Error && error.message) || '',
          severity: ToastSeverities.Error,
          life: ToastLife.Default
        })
      }
    }

    onMounted(async () => {
      try {
        const [
          _contexts,
          _languages,
          _entries
        ] = await Promise.all([
          getTranslationsContexts(),
          getTranslationsLanguages(),
          getTranslationsEntries(contextId)
        ])
        entries.value = _entries
        context.value = _contexts.find(c => c.key === contextId)

        const { ES, AR, MX, BR, CL, CO, US } = CountriesISO
        const sortLanguagesByCountry = [ES, AR, MX, BR, CL, CO, US]
        languages.value = _languages.sort((a, b) => {
          const aKey = a.countryCode.toLowerCase()
          const bKey = b.countryCode.toLowerCase()
          return sortLanguagesByCountry.findIndex(l => l === aKey) - sortLanguagesByCountry.findIndex(l => l === bKey)
        })

        const preSelectedLanguages = new Map([ES, BR, AR, MX].map(c => [c.toString().toUpperCase(), c]))
        selectedLanguages.value = _languages.filter(l => preSelectedLanguages.has(l.countryCode))
      } catch (error) {
        toast.add({
          summary: ToastSummaries.Error,
          detail: 'No se pudieron obtener los lenguajes de traducción',
          severity: ToastSeverities.Error,
          life: ToastLife.Default
        })
      } finally {
        loading.value = false
      }
    })

    return {
      addKey,
      editKey,
      onDelete,
      context,
      languages,
      loading,
      selectedLanguages,
      selectedEntry,
      entries,
      saveEntry,
      onSelectionUpdated,
      onCancelEditing,
      onPublish,
      onClearCache,
      onSelectionLanguages (selected: Array<TranslationLanguage>) {
        selectedLanguages.value = selected
      }
    }
  }
})
