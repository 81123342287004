

import { TranslationContext } from '@/models/translations/TranslationContext'
import TranslationsLanguagesSelectorPanel from '@/components/translations/TranslationsLanguagesSelectorPanel.vue'
import TranslationEntryTextsEditor from '@/components/translations/TranslationEntryTextsEditor.vue'
import { TranslationEntry } from '@/models/translations/TranslationEntry.model'
import { TranslationLanguage } from '@/models/translations/TranslationLanguage.model'
import { FilterMatchMode } from 'primevue/api'
import Button from 'primevue/button'
import ToggleButton from 'primevue/togglebutton'
import DataTable from 'primevue/datatable'
import { computed, defineComponent, PropType, ref, watch, onMounted } from 'vue'
import isEqual from 'lodash.isequal'

export default defineComponent({
  name: 'TranslationsKeysTable',
  components: {
    Button,
    DataTable,
    ToggleButton,
    TranslationEntryTextsEditor,
    TranslationsLanguagesSelectorPanel
  },
  emits: [
    'onAddKey',
    'onKeyEdited',
    'onCancelEditing',
    'onPublish',
    'onEditKey',
    'onDelete',
    'update:selectedEntry',
    'clear:cache',
    'update:modelValue',
    'update:selectedLanguages'
  ],
  props: {
    context: {
      type: Object as PropType<TranslationContext>,
      required: false
    },
    allLanguages: {
      type: Object as PropType<Array<TranslationLanguage>>,
      required: true
    },
    languages: {
      type: Object as PropType<Array<TranslationLanguage>>,
      required: true
    },
    modelValue: {
      type: Object as PropType<Array<TranslationLanguage>>,
      required: true
    },
    entries: {
      type: Object as PropType<Array<TranslationEntry>>,
      required: true
    },
    selectedEntry: {
      type: Object as PropType<TranslationEntry>,
      required: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    const showPublished = ref<boolean>(false)
    const keyToAdd = ref<string>('')
    const selectedLanguages = ref<Array<TranslationLanguage>>([])
    const currentEntry = computed({
      get () {
        return props.selectedEntry
      },
      set (entry: TranslationEntry | undefined) {
        emit('update:selectedEntry', entry)
      }
    })
    const editingEntry = ref(props.selectedEntry)

    const filters = ref({
      key: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS
      }
    })

    const shouldPublish = (entry: TranslationEntry) => entry && !isEqual(entry.draft, entry.published)

    watch(() => props.selectedEntry, (newEntry) => {
      editingEntry.value = newEntry
    })

    const addKey = () => {
      emit('onAddKey', keyToAdd.value)
    }

    const editKey = () => {
      emit('onEditKey', editingEntry.value)
    }

    const deleteCurrent = () => {
      emit('onDelete', editingEntry.value)
    }

    const cancelEditing = () => {
      emit('onCancelEditing', keyToAdd.value)
    }

    const saveCurrent = () => {
      const editingValue = editingEntry.value
      if (editingValue) {
        editingEntry.value = undefined
        emit('onKeyEdited', editingValue)
      }
    }

    const publishCurrent = (entry: TranslationEntry) => {
      emit('onPublish', entry)
    }
    const superuser = location.search.includes('translations-superuser')

    const exporDataTable = ref<any>()
    const onClickDownloadCSV = ():any => exporDataTable.value.exportCSV()

    const title = computed(() => `Contexto '${props.context?.name || 'pending'}' - Selecciones los lenguajes para editar (al menos 1)`)
    const clearCache = () => emit('clear:cache')

    onMounted(() => {
      selectedLanguages.value = props.languages
    })

    return {
      selectedLanguages,
      title,
      exporDataTable,
      addKey,
      editKey,
      deleteCurrent,
      filters,
      keyToAdd,
      saveCurrent,
      cancelEditing,
      currentEntry,
      editingEntry,
      showPublished,
      publishCurrent,
      shouldPublish,
      superuser,
      onClickDownloadCSV,
      clearCache,
      onUpdateSelectedLanguages: () => {
        emit('update:selectedLanguages', selectedLanguages.value)
      }
    }
  }
})
