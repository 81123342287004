
import { TranslationContext } from '@/models/translations/TranslationContext'
import { TranslationLanguage } from '@/models/translations/TranslationLanguage.model'
import SelectButton from 'primevue/selectbutton'
import { computed, defineComponent, PropType } from 'vue'
import CountryFlag from 'vue-country-flag-next'

export default defineComponent({
  name: 'TranslationsLanguagesSelectorPanel',
  props: {
    context: {
      type: Object as PropType<TranslationContext>,
      required: false
    },
    modelValue: {
      type: Object as PropType<Array<TranslationLanguage>>,
      required: true
    },
    languages: {
      type: Object as PropType<Array<TranslationLanguage>>,
      required: true
    }
  },
  emit: [
    'update:modelValue',
    'clear:cache'
  ],
  components: {
    CountryFlag,
    SelectButton
  },
  setup (props, { emit }) {
    const selected = computed({
      get () {
        return props.modelValue
      },
      set (value: Array<TranslationLanguage>) {
        if (value.length > 0) emit('update:modelValue', value)
      }
    })

    const title = computed(() => `Contexto '${props.context?.name || 'pending'}' - Selecciones los lenguajes para editar (al menos 1)`)
    const clearCache = () => emit('clear:cache')

    return {
      selected,
      title,
      clearCache
    }
  }
})
