import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "translations-languages-selector-panel-container" }
const _hoisted_2 = { class: "p-d-flex p-justify-between" }
const _hoisted_3 = { class: "p-ml-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_country_flag = _resolveComponent("country-flag")!
  const _component_SelectButton = _resolveComponent("SelectButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_SelectButton, {
        modelValue: _ctx.selected,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.selected = $event)),
        options: _ctx.languages,
        multiple: "",
        dataKey: "id"
      }, {
        option: _withCtx(({ option }) => [
          _createVNode(_component_country_flag, {
            country: option.countryCode,
            size: "small"
          }, null, 8, ["country"]),
          _createElementVNode("span", _hoisted_3, _toDisplayString(option.languageCode) + "_" + _toDisplayString(option.countryCode), 1)
        ]),
        _: 1
      }, 8, ["modelValue", "options"])
    ])
  ]))
}